body {
  background-color: #FBFBFB;
}

.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  background-image: url('./bg.png');
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.App-header {
  background-color: #fbfbfb;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 45px;
  padding-right: 45px;
  padding-top: 25px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 45px;
  margin-right: 10px;
}

.company-name {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

nav ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

nav{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

nav ul li {
  cursor: pointer;
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.login {
  color: #8780EC;
}

.signup {
  background-color: #8780EC;
  color: #FEFEFE;
  padding: 10px 40px;
  border-radius: 5px;
  text-decoration: none;
}

a{
  text-decoration: none;  
  color: #000000;
}

main {
  padding: 0px;
}

h1 {
  color: #000000;
  font-size: 85px;
  margin-bottom: 20px;
}

span {
  color: #8780EC;
}

h2 {
  color: #333;
  font-size: 22px;
  margin-bottom: 30px;
  font-weight: 400;
}

button {
  background-color: #8780EC;
  color: #ffffff;
  border: none;
  padding: 10px 55px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500;
}

.logo-section {
  margin-top: 100px;
  margin-bottom: 50px;
}

.demo-section {
  margin-top: 200px;
}

.demo{
  max-width: 80%;
}

.logo-section h3 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #000000;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  text-align: center; /* Add this line */

}

.logo1{
  display: block; /* Add this line */
  max-height: 80px;
  margin: 0 auto; /* Add this line */

}

.logo2{
  display: block; /* Add this line */
  max-height: 50px;
  margin: 0 auto; /* Add this line */

}



@media screen and (max-width: 768px) {
  .App-header {
    flex-direction: column;
    padding: 20px;
  }

  .demo{
    max-width: 95%;
  }

  .demo-section{
    margin-top: 50px;
  }

  .logo-container {
    margin-bottom: 20px;
  }

  nav ul {
    flex-direction: column;
    gap: 10px;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 18px;
  }

  button {
    padding: 10px 30px;
    font-size: 16px;
  }
}

/* ... previous styles ... */

.hamburger {
  display: none;
  cursor: pointer;
}

.line {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px;
  transition: all 0.3s ease;
}

@media screen and (max-width: 768px) {
  .App-header {
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
  }

  .logo-container {
    margin-bottom: 0;
  }

  .menu {
    display: none;
    position: absolute;
    top: 100px;
    right: 0;
    width: 100%;
    background-color: #fbfbfb;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .menu.open {
    display: block;
  }

  .menu ul {
    flex-direction: column;
    gap: 10px;
  }

  .hamburger {
    display: block;
  }

  .hamburger.open .line:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .hamburger.open .line:nth-child(2) {
    opacity: 0;
  }

  .hamburger.open .line:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
  }
}