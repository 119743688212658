.how-it-works {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 100px;
}

.step {
  display: flex;
  align-items: flex-start; /* Align items at the top */
  justify-content: space-between; /* Maintain original spacing */
  margin-bottom: 40px;
}

.how-it-works h2 {
  color: #000000;
  font-size: 65px;
  margin-bottom: 20px;
  font-weight: 500;
  text-align: center; /* Center the heading */
  margin-bottom: 50px;
}

.step-number {
  background-color: #8bd59b;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin: 20px 20px 20px 0; /* Adjust margin */
}

.step-description {
  flex: 1;
  margin: 0;
  text-align: left;
  padding-right: 50px; /* Ensure gap between text and image */
}

.step-example, .step-example2 {
  max-width: 500px;
  margin-top: 20px;
  flex-shrink: 0; /* Prevent the image from shrinking */
}

.step-example img {
  width: 90%;
  height: auto;
}

.step-example2 img{
  width: 60%;
  height: auto;
}

/* Remove unnecessary negative margins */
.step-example2 {
  margin-left: 20px; /* Adjust if necessary for alignment */
}

/* Additional adjustment for the second step to align properly */
.step:nth-child(even) .step-description {
  padding-left: 20px; /* Ensure gap between text and image for reversed steps */
  padding-right: 0; /* Reset right padding for consistency */
}

.step:nth-child(even) .step-number {
  margin: 20px 0 20px 50px; /* Adjust margin for step number in reversed steps */
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .how-it-works {
    padding: 10px;
  }

  .how-it-works h2 {
    font-size: 40px; /* Smaller font size for mobile */
    margin-bottom: 10px;
  }

  .step {
    flex-direction: column;
    align-items: center; /* Center align items for mobile */
    text-align: center; /* Center text for mobile */
  }

  .step-description,
  .step-example, 
  .step-example2 {
    max-width: 100%; /* Full width on mobile */
    padding-right: 0; /* Reset padding for mobile */
    text-align: center;
  }

  .step-number {
    margin-bottom: 10px; /* Adjust margin for mobile layout */
    display: none;
  }

  .step:nth-child(3) {
  flex-direction: column-reverse;
}
.step-example img {
  width: 100%; /* Full width images on mobile */
}

  
  .step-example2 img {
    width: 70%; /* Full width images on mobile */
    margin: 0;
    padding: 0;
  }

  /* Adjust the layout for even steps on mobile */
  .step:nth-child(even) .step-description,
  .step:nth-child(even) .step-number {
    padding-left: 0;
    margin: 0; /* Reset margins and padding for mobile */
  }
}
