.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: fadeIn 0.3s ease-in-out;
}

.popup-content {
  background-color: #fbfbfb;
  padding: 100px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 20px;
  text-align: center;
  max-width: 1000px;
  width: 90%;
  position: relative;
  animation: slideIn 0.3s ease-in-out;
}

.popup-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.popup-content h3 {
  font-size: 18px;
  margin-bottom: 20px;
}

.popup-content form {
  display: flex;
  flex-direction: row; /* Updated for vertical layout */
  justify-content: center;
  align-items: center; /* Align form elements */
  gap: 10px; /* Space between form elements */
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 100px;
}


.popup-content input[type="text"],
.popup-content select { /* Added styles for text input and select */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%; /* Full width within form */
}

.popup-content input[type="email"]{
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%; /* Full width within form */
  min-width: 300px;
}

.popup-content button[type="submit"] {
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
  width: 150px;
  font-size: 18px;
  background-color: #8780EC;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: rgb(0, 0, 0);
}

.title {
  font-size: 30px;
}

.popup-content form .g-recaptcha {
  margin-bottom: 1rem;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes slideIn {
  from {
      transform: translateY(-50px);
  }
  to {
      transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .popup-content {
      padding: 50px;
  }

  .popup-content form {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 50px;
      flex-direction: column;
  }

  .popup-content input[type="email"],
  .popup-content input[type="text"],
  .popup-content select,
  .popup-content button[type="submit"] {
    width: 100%; /* This ensures full width within the form's content area */
    box-sizing: border-box; /* Ensures padding and border are included in the width */
  }

  .title {
      font-size: 24px;
  }
}
