/* widget.css */
.widget-container {
    background-color: #2c2f36;
    border-radius: 8px;
    padding: 20px;
    color: white;
    max-width: 600px;
    margin: auto;
    margin-bottom: 100px;
  }
  
  .widget-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .widget-title {
    font-size: 24px;
    margin: 0;
    color: azure;
  }
  
  .widget-reviews-summary {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .widget-score {
    font-size: 20px;
    font-weight: bold;
  }
  
  .widget-review-count {
    font-size: 20px;
    color: #777;
  }
  
  .review-card {
    background: #1e2127;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .review-content {
    flex-grow: 1;
  }
  
  .review-author {
    font-weight: bold;
    margin: 0;
  }
  
  .review-text {
    font-size: 14px;
    color: #aaa;
    margin: 0;
  }
  
  .star-rating {
    color: #ffc107; /* gold color for the stars */
  }
  
  /* You can add more styling for responsiveness or interactive elements */
  