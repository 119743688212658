.container {
    background-color: #000000;
    padding: 80px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px;
    border-radius: 20px;
    position: relative;
  }
  
  .headingContainer {
    margin-right: 40px;
    text-align: left;
  }
  
  .heading {
    max-width: 300px;
  }
  
  .title {
    font-size: 36px;
    font-weight: 600;
    color: #8bd59b;
    margin-bottom: 10px;
  }
  
  .subtitle {
    font-size: 24px;
    font-weight: 600;
    color: #e3e3e3;
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 16px;
    color: #9f9f9f;
    line-height: 1.5;
  }
  
  .solutionContainer {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
  }
  
  .solution {
    max-width: 200px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 24px;
    text-align: left;
  }
  
  .solutionHeader {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  
  .solutionIcon {
    font-size: 24px;
    margin-right: 12px;
  }
  
  .solutionTitle {
    font-size: 20px;
    font-weight: 600;
    color: #333333;
  }
  
  .solutionDescription {
    font-size: 16px;
    color: #555555;
    line-height: 1.5;
  }
  
  .solutionButton {
    min-width: 200px;
    padding: 10px 20px;
    background-color: #8bd59b;
    color: #000000;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: flex-start;
      margin: 20px;
      padding: 40px 20px;
    }
  
    .headingContainer {
      margin-right: 0;
      margin-bottom: 40px;
      text-align: center;
    }
  
    .heading {
      max-width: 100%;
    }
  
    .solutionContainer {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  
    .solution {
      max-width: 100%;
    }
  }