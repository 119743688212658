.container {
  background-color: transparent;
  padding: 80px 40px;
  text-align: center;
}

.title {
  font-size: 60px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 40px;
}

.problemsContainer {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.problem {
  max-width: 300px;
  background-color: #000000;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 24px;
  text-align: left;
}

.problemTitle {
  font-size: 20px;
  font-weight: 600;
  color: #FBFBFB;
  margin-bottom: 12px;
}

.problemDescription {
  font-size: 16px;
  color: #949494;
  line-height: 1.5;
}

@media (max-width: 768px) {
    .container {
      padding: 40px 20px;
    }
  
    .problemsContainer {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  
    .problem {
      max-width: 100%;
    }

    .title{
        color: black;
    }
  }